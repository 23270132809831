<template>
  <div class="d-flex align-items-center">
    <div class="d-flex align-items-center" v-if="false">
      <label class="inline-label">Search</label>

      <b-input-group class="input-group-merge">
        <b-form-input v-model="searchTerm" placeholder="Cari data"
          @keyup.enter="$emit('keyupEnterSearch', searchTerm)" />
        <b-input-group-append is-text>
          <feather-icon class="text-light" icon="SearchIcon" />
        </b-input-group-append>
      </b-input-group>
    </div>

    <b-button v-b-toggle.advance-search-sidebar class="ml-1" variant="info">
      <feather-icon icon="SearchIcon" />
      <span class="d-none d-md-inline">Search</span>
    </b-button>

    <b-button v-if="addButton" class="ml-1" variant="success" @click.prevent="$emit('addButtonAction')">
      <feather-icon icon="PlusIcon" />
      <span class="d-none d-md-inline">Add</span>
    </b-button>

    <!-- SIDEBAR -->
    <b-sidebar id="advance-search-sidebar" class="custom-sidebar" bg-variant="white" right shadow no-header>
      <template #default="{ hide }">
        <div class="sidebar-header">
          <h5 class="sidebar-title">
            Advance Search
          </h5>
          <b-button class="sidebar-close-btn" @click="() => handleCloseSidebar(hide)">
            <feather-icon class="text-body" icon="XIcon" size="20" />
          </b-button>
        </div>
        <div class="sidebar-content">
          <vue-form-json-schema v-model="model" :schema="schema" :ui-schema="uiSchema" />
          <div class="d-flex align-items-center">
            <b-button class="mr-75 text-white" block variant="dark" @click="resetAdvanceSearch(hide)">
              <feather-icon icon="RefreshCcwIcon" /> Reset
            </b-button>
            <b-button class="mt-0" block variant="primary" :disabled="model.Keyword === ''"
              @click="handleAdvanceSearch(hide)">
              <feather-icon class="mr-50" icon="SearchIcon" /> Search
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
    <!-- SIDEBAR -->
  </div>
</template>

<script>
import schema from './schema/SearchSchema.json'
import uiSchema from './schema/SearchUiSchema.json'

export default {
  props: {
    addButton: {
      type: Boolean,
    },
  },
  data() {
    return {
      searchTerm: '',
      model: { Keyword: '' },
      schema,
      uiSchema,
    }
  },
  methods: {
    handleAdvanceSearch(hide) {
      this.$emit('handleAdvanceSearch', this.model)
      this.handleCloseSidebar(hide)
    },
    resetAdvanceSearch(hide) {
      this.model = { Keyword: '' }
      this.$emit('resetAdvanceSearch', this.model.Keyword)
    },
    handleCloseSidebar(hide) {
      hide()
    },
  },
}
</script>